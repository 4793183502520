@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.app{
	font-size: 12px;
	&.sidebar{
		:global(.sidebar-rt){
			margin-left: -15px;
		}
		.container-sidebar{
			@include clearfix();
		}
		.container-body{
			@include clearfix();
			margin-left: 100px;
		}
	}
}
