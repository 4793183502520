@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.audit-settings-rt{
	@include componentDefaultFonts();
	@include clearfix();
	.panel{
		@include clearfix();
		.actions{
			float: right;
			margin-bottom: 10px;
			button{
				&:first-child{
					margin-right: 10px;
				}
			}
		}
		.subtitle{
			font-size: 16px;
			font-weight: 500;
			color: $primary-color;
		}
		.table-metas{
			border: none;
		}
		:global(.select-rt){
			margin-bottom: 15px;
		}
		:global(.tabs-active-content){
			padding-top: 40px;
		}
	}
	
}