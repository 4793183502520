@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.audit-dashboard-rt{
    @include componentDefaultFonts();

	// Fix para select do revex
	.not-development{
		:global(.select-multiple){
			margin-top: 0px !important;
		}
	}

	.margin-botton-10{
		margin-bottom: 10px;
	}

	.chart-container {
		svg {
			width: 100%;
		}
	}

	.panel-total-incidents{
        min-height: 290px;
        >div:last-child{
            padding-top: 30px;
			>div{
				>div{
					margin-bottom: 50px;
					div:first-child{
						font-size: 14px;
                    	font-weight: bold;
					}
				}
			}
        }
    }

	.panel-inconsistency-year, .panel-infraction-year{
		:global(.table-rt){
			text-align: center;
			:global(.header-table){
				th{
					white-space: pre-wrap;
				}
			}
			tr{
				td{
					&:first-child{
						text-align: right;
					}
				}
			}
		}
	}
	
	.panel{
		>div:first-child{
			>div:last-child{
				color: $primary-color;
			}
		}

		:global(.box-meta-rt){
			margin-top: 160px;
		}
		
	}

	.panel-hidden-liability, .panel-incidents{
		
        >div:last-child {
			>div{
				>div:last-child{
					padding: 20px;
					float: right;
				}
			}
			
		}
	}

	.audit-dashboard-filters{
        position: absolute;
        right: 25px;

        display: inline-flex;
        justify-content: flex-end;
        width: auto;

	}

	.panel-alert-messages{
        >div:last-child{
            padding: 10px 0;
            padding-top: 0;

            .box-message-legal-terms{
                >div{
                    @include borderRadiusTop(15px);
                }
            }
        }
    }

	@media (max-width: 1200px){
        .row-dashboard{
            display: flex;
            flex-direction: column-reverse;
        }
        .hide-small{
            display: none;
        }
    }

}